'use client';

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useReadLocalStorage } from 'usehooks-ts';

import { CustomerMetaField } from '@lib/machine-parts/storefront/account/data-access';
import {
    CheckoutAction,
    CheckoutFragment,
    CheckoutWithShippingRatesFragment,
} from '@lib/machine-parts/storefront/checkout/data-access';
import {
    InternationalizationProps,
    LocalStorageKey,
    ShippableCountry,
    SupportedLanguage,
    useMixpanel,
} from '@lib/machine-parts/storefront/utils';
import { useCart } from '@shopify/hydrogen-react';

import {
    CheckoutAttributesUpdateV2Input,
    CheckoutCreateInput,
    CheckoutLineItemUpdateInput,
    LanguageCode,
    MailingAddressInput,
} from '../generated/graphql';
import { useGetCheckoutCompletedTimeMutation } from '../hooks/useGetCheckoutCompletedTimeMutation';
import { checkoutApi, CheckoutPayload } from '../utils/CheckoutUtils';

export interface CheckoutProps {
    defaultCheckout?: CheckoutFragment;
    countryCode?: ShippableCountry;
    languageCode?: SupportedLanguage;
    children: React.ReactNode;
}

export interface CheckoutContext {
    checkout?: CheckoutWithShippingRatesFragment;
    vatNumber?: string;
    orderId?: string;
    create: (payload?: CheckoutCreateInput) => Promise<void>;
    updateAttributes: (payload: CheckoutAttributesUpdateV2Input) => Promise<string | undefined>;
    removeLineItems: (lineItemIds: string[]) => void;
    updateLineItems: (lineItems: CheckoutLineItemUpdateInput[]) => void;
    updateEmail: (email: string) => Promise<string | undefined>;
    updateShippingAddress: (shippingAddress: MailingAddressInput) => Promise<string | undefined>;
    updateBillingAddress: (billingAddress: MailingAddressInput) => Promise<string | undefined>;
    getAvailableShippingRates: () => void;
    updateShippingLine: (shippingRateHandle: string) => void;
    addDiscountCode: (discountCode: string) => void;
    removeDiscountCode: () => void;
    complete: () => Promise<string | undefined>;
    clear: () => void;
    loadingAvailableShippingRates: boolean;
    loadingSubmit: boolean;
    loading: boolean;
    setLoadingSubmit: React.Dispatch<React.SetStateAction<boolean>>;
    setLoadingAvailableShippingRates: React.Dispatch<React.SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const _CheckoutContext = createContext<CheckoutContext>(undefined!);

// TODO: Stop component from reloading everything on a rerender
export function CheckoutProviderClient({
    children,
    countryCode,
    language,
    locale,
}: CheckoutProps & InternationalizationProps) {
    const localCheckout = useReadLocalStorage<CheckoutFragment>('checkout') ?? undefined;
    const localVatNumber = localCheckout?.customAttributes?.find(
        (attribute) => attribute.key === CustomerMetaField.Vat,
    )?.value;
    const [checkout, setCheckout] = useState<CheckoutFragment | undefined>(localCheckout);
    const [vatNumber, setVatNumber] = useState<string | undefined>(localVatNumber);

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingAvailableShippingRates, setLoadingAvailableShippingRates] = useState(false);
    const { lines = [] } = useCart();
    const { mutateAsync: getCheckoutCompletedTime } = useGetCheckoutCompletedTimeMutation(checkout?.id ?? '');

    const mixpanel = useMixpanel();

    const updateCheckout = useCallback(
        (newCheckout: CheckoutFragment | undefined) => {
            if (newCheckout && (checkout?.id || newCheckout.id)) {
                const mergedCheckout = {
                    ...checkout,
                    ...newCheckout,
                };

                setCheckout(mergedCheckout);
                localStorage.setItem(LocalStorageKey.CHECKOUT, JSON.stringify(mergedCheckout));
            } else {
                setCheckout(undefined);
                localStorage.removeItem(LocalStorageKey.CHECKOUT);
            }
        },
        [checkout],
    );

    const handleCheckoutApi = useCallback(
        async (action: CheckoutAction, payload?: CheckoutPayload) => {
            if (![CheckoutAction.CREATE].includes(action) && !checkout?.id) return;
            setLoading(true);
            const apiResponse = await checkoutApi(action, payload, countryCode);

            if (!apiResponse) {
                await checkoutApi(CheckoutAction.CLEAR, undefined, countryCode);
                updateCheckout(undefined);
            }

            mixpanel.track(`Checkout API ${action}`, { action, payload, apiResponse });

            const _checkout: CheckoutFragment | undefined = apiResponse?.checkout;
            const _orderId = apiResponse?.orderId;

            if (_orderId) {
                setLoading(false);
                return _orderId;
            }

            if (_checkout) {
                if (_checkout.lineItems.nodes.length) {
                    const _vatNumber = _checkout.customAttributes?.find(
                        (attribute) => attribute.key === CustomerMetaField.Vat,
                    )?.value;

                    if (_vatNumber) {
                        setVatNumber(_vatNumber);
                    }

                    updateCheckout(_checkout);
                } else {
                    await checkoutApi(CheckoutAction.CLEAR, undefined, countryCode);
                    updateCheckout(undefined);
                }
            }

            setLoading(false);
            return;
        },
        [checkout?.id, countryCode, mixpanel, updateCheckout],
    );

    const clear = useCallback(async () => {
        await handleCheckoutApi(CheckoutAction.CLEAR);
        setCheckout(undefined);
        localStorage.removeItem(LocalStorageKey.CHECKOUT);
        localStorage.removeItem(LocalStorageKey.SHOPIFY_CART_ID);
    }, [handleCheckoutApi]);

    const handleUserReturning = useCallback(async () => {
        if (checkout?.id) {
            const completedTime = await getCheckoutCompletedTime();
            if (completedTime) {
                await clear();
            }
        }
    }, [checkout?.id, clear, getCheckoutCompletedTime]);

    useEffect(() => {
        window.addEventListener('focus', handleUserReturning);
        return () => {
            window.removeEventListener('focus', handleUserReturning);
        };
    }, [handleUserReturning]);

    const create = useCallback(
        async (payload?: CheckoutCreateInput) => {
            setLoadingSubmit(false);

            const _payload = {
                lineItems: lines.reduce<{ quantity: number; variantId: string }[]>(
                    (items, line) =>
                        line?.quantity && line.merchandise?.id
                            ? [...items, { quantity: line.quantity, variantId: line.merchandise.id }]
                            : items,
                    [],
                ),
            };

            await handleCheckoutApi(CheckoutAction.CREATE, {
                ...(payload ?? _payload),
                countryCode: locale ?? countryCode,
                language: language?.toUpperCase() as LanguageCode,
            });
        },
        [countryCode, handleCheckoutApi, language, lines, locale],
    );

    const updateAttributes = useCallback(
        async (payload: CheckoutAttributesUpdateV2Input) =>
            await handleCheckoutApi(CheckoutAction.UPDATE_ATTRIBUTES, payload),
        [handleCheckoutApi],
    );

    const removeLineItems = useCallback(
        async (lineItems: string[]) => await handleCheckoutApi(CheckoutAction.REMOVE_LINE_ITEMS, lineItems),
        [handleCheckoutApi],
    );

    const updateLineItems = useCallback(
        async (lineItems: CheckoutLineItemUpdateInput[]) =>
            await handleCheckoutApi(CheckoutAction.UPDATE_LINE_ITEMS, lineItems),
        [handleCheckoutApi],
    );

    const updateEmail = useCallback(
        async (email: string) => await handleCheckoutApi(CheckoutAction.UPDATE_EMAIL, email),
        [handleCheckoutApi],
    );

    const updateShippingAddress = useCallback(
        async (shippingAddress: MailingAddressInput) =>
            await handleCheckoutApi(CheckoutAction.UDPATE_SHIPPING_ADDRESS, shippingAddress),
        [handleCheckoutApi],
    );

    const updateBillingAddress = useCallback(
        async (billingAddress: MailingAddressInput) =>
            await handleCheckoutApi(CheckoutAction.UPDATE_BILLING_ADDRESS, billingAddress),
        [handleCheckoutApi],
    );

    const getAvailableShippingRates = useCallback(
        async () => await handleCheckoutApi(CheckoutAction.GET_AVAILABLE_SHIPPING_RATES),
        [handleCheckoutApi],
    );

    const updateShippingLine = useCallback(
        async (shippingRateHandle: string) =>
            await handleCheckoutApi(CheckoutAction.UPDATE_SHIPPING_LINE, shippingRateHandle),
        [handleCheckoutApi],
    );

    const addDiscountCode = useCallback(
        async (discountCode: string) => await handleCheckoutApi(CheckoutAction.ADD_DISCOUNT_CODE, discountCode),
        [handleCheckoutApi],
    );

    const removeDiscountCode = useCallback(
        async () => await handleCheckoutApi(CheckoutAction.REMOVE_DISCOUNT_CODE),
        [handleCheckoutApi],
    );

    const complete = useCallback(
        async (customerId?: string) => await handleCheckoutApi(CheckoutAction.COMPLETE, customerId),
        [handleCheckoutApi],
    );

    const value: CheckoutContext = useMemo(() => {
        return {
            checkout,
            vatNumber,
            create,
            updateAttributes,
            removeLineItems,
            updateLineItems,
            updateEmail,
            updateShippingAddress,
            updateBillingAddress,
            getAvailableShippingRates,
            updateShippingLine,
            addDiscountCode,
            removeDiscountCode,
            complete,
            clear,
            loadingAvailableShippingRates,
            loadingSubmit,
            loading,
            setLoadingAvailableShippingRates,
            setLoadingSubmit,
        };
    }, [
        checkout,
        vatNumber,
        create,
        updateAttributes,
        removeLineItems,
        updateLineItems,
        updateEmail,
        updateShippingAddress,
        updateBillingAddress,
        getAvailableShippingRates,
        updateShippingLine,
        addDiscountCode,
        removeDiscountCode,
        complete,
        clear,
        loadingAvailableShippingRates,
        loadingSubmit,
        loading,
    ]);

    return <_CheckoutContext.Provider value={value}>{children}</_CheckoutContext.Provider>;
}

export const useCheckout = () => useContext(_CheckoutContext);
