import axios from 'axios';
import _ from 'lodash';

import { CustomerMetaField } from '@lib/machine-parts/storefront/account/data-access';
import { CheckoutAction, CheckoutFragment } from '@lib/machine-parts/storefront/checkout/data-access';
import { PaymentMethod } from '@lib/machine-parts/storefront/checkout/utils';
import {
    formatPhone,
    getCountryCallingCode,
    SHIPPABLE_COUNTRIES,
    ShippableCountry,
} from '@lib/machine-parts/storefront/utils';

import { CurrencyCode, MoneyV2 } from '../generated/graphql';
import {
    AttributeInput,
    DraftOrderAppliedDiscountType,
    DraftOrderInput,
    DraftOrderLineItemInput,
    MailingAddressInput,
} from '../generated/graphql-admin';

export type CheckoutApiResponse = { checkout: CheckoutFragment; errors: any[] };
export type CheckoutPayload = any;

export function mapCheckoutToDraftOrder(checkout: CheckoutFragment): DraftOrderInput {
    const { email, customAttributes, note, shippingAddress, lineItems, discountApplications } = checkout;

    const _shippingAddress: MailingAddressInput = _.omit(shippingAddress, ['__typename']);
    const phone = formatPhone(shippingAddress?.phone as string).phoneNumber ?? undefined;
    const discountCode = (discountApplications.nodes[0] as { code?: string })?.['code'];
    const discount = discountApplications.nodes[0]?.value;

    let discountValue = 0;
    let discountValueType = DraftOrderAppliedDiscountType.Percentage;
    if (discount?.__typename === 'MoneyV2') {
        discountValue = parseFloat(discount.amount);
        discountValueType = DraftOrderAppliedDiscountType.FixedAmount;
    }

    if (discount?.__typename == 'PricingPercentageValue') {
        discountValue = discount.percentage;
        discountValueType = DraftOrderAppliedDiscountType.Percentage;
    }

    return {
        customAttributes: customAttributes?.map(({ __typename, ...attr }) => attr) as AttributeInput[],
        email,
        lineItems: mapCheckoutLineItemsToDraftOrderLineItems(lineItems.nodes),
        note,
        phone,
        marketRegionCountryCode: checkout.buyerIdentity?.countryCode,
        shippingAddress: _shippingAddress,
        ...(discountApplications.nodes.length > 0 && {
            appliedDiscount: {
                title: discountCode,
                value: discountValue,
                valueType: discountValueType,
            },
        }),
    };
}

export function mapCheckoutLineItemsToDraftOrderLineItems(
    lineItems: CheckoutFragment['lineItems']['nodes'],
): DraftOrderLineItemInput[] {
    return lineItems.map(mapCheckoutLineItemToDraftOrderLineItem);
}

export function mapCheckoutLineItemToDraftOrderLineItem(
    lineItem: CheckoutFragment['lineItems']['nodes'][number],
): DraftOrderLineItemInput {
    const { customAttributes, quantity, variant } = lineItem;

    return {
        customAttributes: customAttributes as AttributeInput[],
        quantity,
        variantId: variant?.id,
    };
}

export function getTotalPrice(price: MoneyV2, quantity: number) {
    const amount = parseFloat(price.amount);
    return { ...price, amount: (amount * quantity).toString() };
}

export async function checkoutApi(
    action: CheckoutAction,
    payload?: CheckoutPayload,
    countryCode?: string,
): Promise<
    | {
          checkout: CheckoutFragment;
          orderId?: string;
      }
    | undefined
> {
    const currentCheckout = localStorage.getItem('checkout');
    const mergedCurrentCheckout = {
        ...(currentCheckout ? JSON.parse(currentCheckout) : {}),
        buyerIdentity: { countryCode },
    };
    try {
        const { data } = await axios.post<CheckoutApiResponse>('/api/checkout', {
            action,
            payload,
            currentCheckout: mergedCurrentCheckout,
        });

        if (data.errors) {
            console.error(JSON.stringify(data.errors));
            return undefined;
        }

        return data;
    } catch (e) {
        console.error('checkoutApi', JSON.stringify(e));
        return undefined;
    }
}

export function getCheckoutTotalDiscount(lineItems: CheckoutFragment['lineItems']) {
    return lineItems.nodes.reduce<MoneyV2>(
        (acc, curr) => {
            const discountAllocation = curr.discountAllocations[0].allocatedAmount;

            return {
                amount: (parseFloat(acc.amount) + parseFloat(discountAllocation.amount)).toFixed(2),
                currencyCode: discountAllocation.currencyCode,
            };
        },
        { amount: '0.0', currencyCode: CurrencyCode.Eur },
    );
}

export function mapLocalCheckoutToDefaultValues({
    checkout,
    country,
}: {
    checkout?: CheckoutFragment;
    country: ShippableCountry;
}) {
    const checkoutPhone = formatPhone(checkout?.shippingAddress?.phone ?? '');
    const vatNumber = checkout?.customAttributes?.find((attribute) => attribute.key === CustomerMetaField.Vat)?.value;
    const countryCode = (
        vatNumber && SHIPPABLE_COUNTRIES.includes(vatNumber.slice(0, 2) as ShippableCountry)
            ? vatNumber?.slice(0, 2)
            : checkout?.shippingAddress?.countryCode ?? country
    ) as ShippableCountry;

    return {
        contact: {
            email: checkout?.email ?? '',
            firstName: checkout?.shippingAddress?.firstName ?? '',
            lastName: checkout?.shippingAddress?.lastName ?? '',
            phone: checkoutPhone.isValid ? checkoutPhone.phoneNumber.replace(checkoutPhone.countryCode, '') : '',
            countryCallingCode: checkoutPhone.isValid ? checkoutPhone.countryCode : getCountryCallingCode(country),
            countryCode: SHIPPABLE_COUNTRIES.includes(countryCode) ? countryCode : SHIPPABLE_COUNTRIES[0],
            vatNumber: vatNumber?.slice(2) ?? '',
            password: '',
        },
        address: [
            {
                countryCode: (checkout?.shippingAddress?.countryCode as ShippableCountry) ?? '',
                company: checkout?.shippingAddress?.company ?? '',
                address1: checkout?.shippingAddress?.address1 ?? '',
                address2: checkout?.shippingAddress?.address2 ?? '',
                zip: checkout?.shippingAddress?.zip ?? '',
                provinceCode: checkout?.shippingAddress?.provinceCode ?? '',
                city: checkout?.shippingAddress?.city === 'Unknown' ? '' : checkout?.shippingAddress?.city ?? '',
                phone: checkoutPhone.isValid ? checkoutPhone.phoneNumber.replace(checkoutPhone.countryCode, '') : '',
                countryCallingCode: checkoutPhone.isValid ? checkoutPhone.countryCode : getCountryCallingCode(country),
            },
        ],
        paymentMethod: PaymentMethod.DEPOSIT,
    };
}
