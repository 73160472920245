'use client';

import { getStorefrontClient } from '@lib/machine-parts/storefront/data-access';
import { getCurrentEnv } from '@lib/machine-parts/storefront/utils';
import { useMutation } from '@tanstack/react-query';

import {
    GetCheckoutCompletedTimeDocument,
    GetCheckoutCompletedTimeQuery,
    GetCheckoutCompletedTimeQueryVariables,
} from '@lib/machine-parts/storefront/checkout/data-access';

export function useGetCheckoutCompletedTimeMutation(checkoutId: string) {
    return useMutation(['checkout-completed-data', checkoutId], async () => {
        const { query } = getStorefrontClient({ environment: getCurrentEnv() });
        const { data, errors } = await query<GetCheckoutCompletedTimeQuery, GetCheckoutCompletedTimeQueryVariables>({
            query: GetCheckoutCompletedTimeDocument,
            variables: { checkoutId },
        });

        // catch errors
        if (!data || !data.node || errors || data?.node.__typename !== 'Checkout') {
            // eslint-disable-next-line no-console
            console.error('No checkout data found');
            return null;
        }

        return data.node.completedAt;
    });
}
